import { StyleDeclarationValue, css } from 'aphrodite';
import * as React from 'react';
import { DeprecatedXIcon } from '../svgs/icons/DeprecatedXIcon';
import { styleSheet } from './styles';

export interface ICloseButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	fillColor?: string;
	opacity?: number;
	outlineColor?: string;
	outlineWidth?: number;
	styles?: StyleDeclarationValue[];
	widthAndHeight?: number;
	xMark?: boolean;
}

export const DeprecatedCloseButton: React.FC<ICloseButtonProps> = props => {
	const {
		fillColor,
		opacity,
		outlineColor,
		outlineWidth,
		widthAndHeight,
		styles = [],
		xMark = false,
		...restProps
	} = props;
	return (
		<button
			{...restProps}
			className={`${css(xMark ? styleSheet.closeButtonXMark : styleSheet.closeButton, ...styles)} ${
				props.className || ''
			}`}
		>
			<DeprecatedXIcon
				className={css(styleSheet.visible)}
				circleFill={xMark ? fillColor : null}
				fillColor={xMark ? '#FFF' : fillColor}
				circleOutline={xMark ? outlineColor : null}
				xIconSize={widthAndHeight}
				transformX={xMark ? -277 : -271}
				transformY={xMark ? -7 : -15}
			/>
			{props.children}
		</button>
	);
};
