export const Topics = {
	APP: 'app',
	/** Use 'onAfterOpen' or 'onAfterClose' (can handle the modal closing) */
	APP_FULLSCREEN_MODAL_APPEARANCE: 'app.fullscreenmodal.appearance',
	APP_REFRESH: 'app.refresh',
	/** Listeners should expect the notification info to be of type IOpportunity */
	ARCHIVE_OPPORTUNITY: 'item.archive.opportunity',
	CREATE_ACTION_ITEM: 'item.create.actionitem',
	CREATE_AUTOMATION: 'item.create.automation', // ICreateAutomationMessage
	CREATE_BOARD_ITEM: 'item.create.boarditem',
	CREATE_CAMPAIGNS_ITEM: 'item.create.campaigns',
	CREATE_CONTACT: 'item.create.contact',
	CREATE_NOTE: 'item.create.note',
	/** Listeners should expect the notification info to be of type SocialMediaPostViewModel */
	CREATE_SOCIAL_POST: 'item.create.socialpost',
	DELETE_ACTION_ITEM: 'item.delete.actionitem',
	DELETE_AUTOMATION: 'item.delete.automation', // IAutomation
	DELETE_CONTACT: 'item.delete.contact',
	EDIT_ACTION_ITEM: 'item.edit.actionitem',
	EDIT_CAMPAIGNS_ITEM: 'item.edit.campaigns',
	EDIT_NOTE: 'item.edit.note',
	/** Listeners should expect the notification info to be of type SocialMediaPostViewModel */
	EDIT_SOCIAL_POST: 'item.edit.socialpost',
	EDIT_SYSTEM_AUTOMATION_TEMPLATE: 'item.edit.systemautomationtemplate', // ISystemAutomationTemplate
	EDIT_SYSTEM_EMAIL_TEMPLATE: 'item.edit.systememailtemplate',
	EDIT_BLOG_POST: 'item.edit.blogpost',
	EMAIL: 'email',
	GHOST_CAL_UPDATE: 'calendar.ghost.update',
	ITEM: 'item',
	ITEM_CREATE: 'item.create',
	ITEM_DELETE: 'item.delete',
	ITEM_EDIT: 'item.edit',
	/** Listeners should expect the notification info to be of type EmailMessageViewModel */
	SEND_EMAIL: 'email.send',
	/** Listeners should expect the notification info to be of type IKeepInTouchReference */
	SEND_KIT_EMAIL: 'email.send.kit',
	TIMELINE_REFRESH: 'timeline.refresh',
};
