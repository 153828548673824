import * as React from 'react';
import { createPortal } from 'react-dom';

export interface IPortalProps {
	/** Element or element id */
	destination: HTMLElement | string;
}

export interface IPortalDestinationProps
	extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
	id: string;
}

interface IState {
	portalNode?: HTMLElement;
}

export class Portal extends React.PureComponent<IPortalProps, IState> {
	public state: IState = {};

	public componentDidMount() {
		this.setStateWithNextProps();
	}

	public UNSAFE_componentWillReceiveProps(nextProps: IPortalProps) {
		this.setStateWithNextProps(nextProps);
	}

	public render() {
		const { portalNode } = this.state;
		if (!portalNode) {
			return null;
		}
		return createPortal(this.props.children, portalNode);
	}

	private setStateWithNextProps = (nextProps: IPortalProps = this.props) => {
		const { destination } = nextProps;

		let portalNode: HTMLElement = null;
		if (!!destination && !!document) {
			portalNode = typeof destination === 'string' ? document.getElementById(destination) : destination;
		}

		if (this.state.portalNode !== portalNode) {
			this.setState({
				portalNode,
			});
		}
	};
}

export const PortalDestination: React.FC<IPortalDestinationProps> = props => {
	const { id, className, ...restProps } = props;
	return <div {...restProps} className={`portal-destination ${className || ''}`} id={id} />;
};
