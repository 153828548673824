import * as React from 'react';
import { SvgIcon } from '../SvgIcon';

interface IProps {
	className?: string;
	circleFill?: string;
	fillColor?: string;
	transformX?: number;
	transformY?: number;
	xIconSize?: number;
	circleOutline?: string;
}

export const DeprecatedXIcon: React.FC<IProps> = props => {
	const {
		className,
		circleOutline = null,
		circleFill = null,
		fillColor = '#757575',
		xIconSize = 12,
		transformX = -271,
		transformY = -15,
	} = props;
	return (
		<SvgIcon className={`x-icon ${className || ''}`} height={xIconSize} width={xIconSize}>
			<g fill='none' fillRule='evenodd' transform='translate(2 2)'>
				<circle
					r={(xIconSize + 6) / 2}
					fill={circleFill}
					stroke={circleOutline}
					strokeOpacity={0.9}
					strokeWidth={1.5}
					transform='translate(0 14)'
				/>
				<polygon
					fill={fillColor}
					fillRule='evenodd'
					points='282.805 16.138 281.862 15.195 277 20.057 272.138 15.195 271.195 16.138 276.057 21 271.195 25.862 272.138 26.804 277 21.943 281.862 26.804 282.805 25.862 277.943 21'
					transform={`translate(${transformX} ${transformY})`}
				/>
			</g>
		</SvgIcon>
	);
};
