import { StyleDeclarationValue } from 'aphrodite';
import * as React from 'react';

export interface IAppBarContext {
	appBarContentStyles?: StyleDeclarationValue[];
	appBarStyles?: StyleDeclarationValue[];
}

export interface IMutableAppBarContext {
	setAppBarContentStyles?(appBarContentStyles?: StyleDeclarationValue[]): void;
	setAppBarStyles?(appBarStyles?: StyleDeclarationValue[]): void;
}

export const AppBarContext = React.createContext<IAppBarContext>({
	appBarContentStyles: [],
	appBarStyles: [],
});

export const MutableAppBarContext = React.createContext<IMutableAppBarContext>(null);

export const AppBarContextProvider: React.FC = ({ children }) => {
	const [context, setContext] = React.useState<IAppBarContext>({
		appBarContentStyles: [],
		appBarStyles: [],
	});
	const mutators = React.useRef<IMutableAppBarContext>({
		setAppBarContentStyles: appBarContentStyles => setContext(v => ({ ...v, appBarContentStyles })),
		setAppBarStyles: appBarStyles => setContext(v => ({ ...v, appBarStyles })),
	}).current;
	return (
		<AppBarContext.Provider value={context}>
			<MutableAppBarContext.Provider value={mutators}>{children}</MutableAppBarContext.Provider>
		</AppBarContext.Provider>
	);
};
